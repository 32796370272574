import React from "react";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import NewsCard from "../components/News";
import { Column, Stack, Row, Img, Text } from "components";
import { Link } from "react-router-dom";
import "./newsCards/newscards.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Scrollbar, A11y } from "swiper";
import { useState } from "react";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
import colors from "tailwindcss/colors";
const ACTUALITYPage = (props) => {
  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  return (
    <>
      {props.isLoading ? (
        <div
          className="mt-[300px] spinner-border text-primary relative ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden ">Loading...</span>
        </div>
      ) : (
        <Column
          style={{ direction: props.direction == 2 ? "rtl" : "ltr" }}
          className="bg-white_A700 font-productsans items-center mx-[auto] pb-[1px] w-[100%]"
        >
          {/* <h1>{props.News ? props.News.title.title : "not found"}</h1> */}
          {/* {props.News.title?.map((value, index) => ( */}

          <Stack key={1} className="w-[100%]">
            <Column
              style={{ height: "350px" }}
              className="news-bg-main  !bg-repeat-round h-[0px] justify-end xl:p-[103px] 2xl:p-[115px] 3xl:p-[139px] lg:p-[90px] top-[0] w-[100%]"
            >
              <LazyLoad offset={300}>
                <ProgressiveImage
                  src={`${props.hostLink}/uploads/${props.News?.title?.main_image}`}
                  // placeholder={props.hostLink + blur_file_path}
                >
                  {(src) => (
                    <div
                      key={1}
                      className="absolute !bg-no-repeat !bg-center !bg-cover h-[400px] justify-end  2xl:p-[115px] 3xl:p-[139px] !left-0 !right-0  top-[0] w-[100%]"
                      style={{
                        backgroundImage: `url(${src})`,
                      }}
                    ></div>
                  )}
                </ProgressiveImage>
              </LazyLoad>
              <Text className="relative    font-bold lg:ml-[42px] ml-[42px] xl:ml-[48px] 2xl:ml-[54px] 3xl:ml-[65px] 2xl:mt-[10px] 3xl:mt-[13px] lg:mt-[8px] mt-[8px] xl:mt-[9px] lg:text-[45px] text-[45px] xl:text-[51px] 2xl:text-[58px] 3xl:text-[70px] text-indigo_600 w-[auto]">
                {props.News?.title?.title}
              </Text>

              <Row className="relative    items-center lg:ml-[42px] ml-[42px] xl:ml-[48px] 2xl:ml-[54px] 3xl:ml-[65px] mr-[auto] 3xl:mt-[10px] lg:mt-[6px] mt-[6px] xl:mt-[7px] 2xl:mt-[8px] w-[12%]">
                <Img
                  src="/images/img_home.svg"
                  className="2xl:h-[11px] 3xl:h-[13px] lg:h-[8px] h-[8px] xl:h-[9px] 2xl:w-[10px] 3xl:w-[12px] lg:w-[7px] w-[7px] xl:w-[8px]"
                />
                <Link
                  onClick={window["carousel"]}
                  to={{ pathname: `${props.Link}/` }}
                >
                  <Text className="font-normal xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[14px] lg:ml-[9px] ml-[9px] not-italic lg:text-[10px] text-[10px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-gray_700 w-[auto]">
                    {props.Labels?.home}
                  </Text>
                </Link>
                <i className="bi bi-arrow-right text-black xl:ml-[10px] 2xl:w-[10px] 3xl:w-[12px] lg:w-[7px] w-[7px] xl:w-[8px]"></i>
                <Text className="font-normal ml-3 not-italic lg:text-[10px] text-[10px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-indigo_600 w-[auto]">
                  {props.News?.title?.sub_title}
                </Text>
              </Row>
            </Column>
          </Stack>
          {/* ))} */}
          <Column
            className=" bg-contain bg-repeat bottom-[13%] items-center 3xl:p-[111px] xl:p-[82px] 2xl:p-[92px] top-[14%] w-[100%]"
            style={{ backgroundImage: "url('/images/img_group2.png')" }}
          >
            <div className="news-section  news-card-container">
              {props.News?.news?.map((newsItem, index) => (
                <>
                  {newsItem?.main_image ? (
                    <Link
                      key={index}
                      to={{ pathname: `news-singlepage/${newsItem.id}` }}
                    >
                      <NewsCard
                        key={index}
                        news_id={newsItem.id}
                        image={`${props.hostLink}/uploads/${newsItem.main_image}`}
                        title={newsItem.name}
                        description={newsItem.breif_description}
                      />
                    </Link>
                  ) : (
                    <NewsCard
                      key={index}
                      news_id={newsItem.id}
                      image={`/images/no-image-news.jpg`}
                      title={newsItem.name}
                      description={newsItem.breif_description}
                    />
                  )}
                </>
              ))}
            </div>
          </Column>
        </Column>
      )}
    </>
  );
};

export default ACTUALITYPage;
