import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomepagePage from "pages/Homepage";
import PageNotFound from "components/pageNotFound/pageNotFound";
import Header from "components/header/header";
import ContactusPage from "pages/Contact";
import Footer from "components/Footer/Footer";
import LOCATIONPage from "pages/Location";
import ACTUALITYPage from "pages/Actuality";
import News_singlepage from "pages/news_singlepage";
import ScrollToTop from "ScrollToTop";
import Services_singlepage from "pages/services_singlepage";
import OurPartners from "components/OurPartners/OurPartners";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "components/cookies/cookies";
import ServicesPage from "components/services/services";

const ProjectRoutes = () => {
  const [language, setLanguage] = useState(1);
  const [direction, setDirection] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setLanguage(window.localStorage.getItem("language"));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("language", language);
  }, [language]);
  if (!language || language == null || language == "") {
    setLanguage(1);
  }
  //const hostLink = "http://localhost/jmart/web";
  const hostLink = "https://www.med-evasan.com/jmart/web";
  const Link = "";

  const [Labels, fetchLabels] = useState([]);
  const getData = () => {
    fetch(`${hostLink}/API/api/labels?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchLabels(res);
      });
  };
  useEffect(() => {
    getData();
  }, [language]);

  //fetch contact us
  const [ContactTitle, fetchContactTitle] = useState([]);
  const getContact = () => {
    fetch(`${hostLink}/API/api/contact-title?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchContactTitle(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getContact();
  }, [language]);

  const [DataContact, fetchDataContact] = useState([]);
  const getDataContact = () => {
    fetch(`${hostLink}/API/api/contact?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchDataContact(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDataContact();
  }, [language]);

  useEffect(() => {
    getNews();
  }, [language]);

  //fetch  news
  const [News, fetchNews] = useState([]);
  const getNews = () => {
    fetch(`${hostLink}/API/api/news?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchNews(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNews();
  }, [language]);

  //fetch single page news
  const [SingleNews, fetchSingleNews] = useState([]);
  const [news_id, fetchnewsid] = useState(1);
  const getSingleNews = () => {
    fetch(`${hostLink}/API/api/news-by-id?id=` + news_id)
      .then((res) => res.json())
      .then((res) => {
        fetchSingleNews(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSingleNews();
  }, [news_id]);

  //fetch location data
  const [LocationTitle, fetchLocationTitle] = useState([]);
  const getLocationTitle = () => {
    fetch(`${hostLink}/API/api/branches-title?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchLocationTitle(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getLocationTitle();
  }, [language]);
  //fetching partners data
  const [DataPartnersTitle, fetchPartnersTitle] = useState([]);
  const getPartnersTitle = () => {
    fetch(`${hostLink}/API/api/partners-title?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchPartnersTitle(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPartnersTitle();
  }, [language]);

  const [DataPartners, fetchDataPartners] = useState([]);
  const getDataPartners = () => {
    fetch(`${hostLink}/API/api/partners?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchDataPartners(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDataPartners();
  }, [language]);

  //fetch who we are
  const [WhoWeAre, fetchWhoWeAre] = useState([]);
  const getWhoWeAre = () => {
    fetch(`${hostLink}/API/api/who-we-are?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchWhoWeAre(res);
      });
  };

  useEffect(() => {
    getWhoWeAre();
  }, [language]);

  const [Records, fetchRecords] = useState([]);
  const getRecords = () => {
    fetch(`${hostLink}/API/api/who-we-are-records?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchRecords(res);
      });
  };

  useEffect(() => {
    getRecords();
  }, [language]);

  //fetch carousel
  const [Data1, fetchData1] = useState([]);
  const getData1 = () => {
    fetch(`${hostLink}/API/api/carousel-1?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchData1(res);
      });
  };

  useEffect(() => {
    getData1();
  }, [language]);

  const [Data2, fetchData2] = useState([]);
  const getData2 = () => {
    fetch(`${hostLink}/API/api/carousel-2?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchData2(res);
      });
  };

  useEffect(() => {
    getData2();
  }, [language]);

  const [Data3, fetchData3] = useState([]);
  const getData3 = () => {
    fetch(`${hostLink}/API/api/carousel-3?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchData3(res);
      });
  };

  useEffect(() => {
    getData3();
  }, [language]);

  const [Data4, fetchData4] = useState([]);
  const getData4 = () => {
    fetch(`${hostLink}/API/api/carousel-4?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchData4(res);
      });
  };

  useEffect(() => {
    getData4();
  }, [language]);

  const [Data5, fetchData5] = useState([]);
  const getData5 = () => {
    fetch(`${hostLink}/API/api/carousel-5?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchData5(res);
      });
  };

  useEffect(() => {
    getData5();
  }, [language]);

  const [Data6, fetchData6] = useState([]);
  const getData6 = () => {
    fetch(`${hostLink}/API/api/carousel-6?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchData6(res);
      });
  };

  useEffect(() => {
    getData6();
  }, [language]);

  const [Data7, fetchData7] = useState([]);
  const getData7 = () => {
    fetch(`${hostLink}/API/api/carousel-7?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchData7(res);
      });
  };

  useEffect(() => {
    getData7();
  }, [language]);
  //fetch values
  const [OurValuesTitle, fetchOurValuesTitle] = useState([]);
  const getValuesTitle = () => {
    fetch(`${hostLink}/API/api/our-values-title?language=${language}`)
      .then((res) => res.json())
      .then((res) => {
        fetchOurValuesTitle(res);
      });
  };

  useEffect(() => {
    getValuesTitle();
  }, [language]);

  const [OurValues, fetchOurValues] = useState([]);
  const getValues = () => {
    fetch(`${hostLink}/API/api/our-values?language=${language}`)
      .then((res) => res.json())
      .then((res) => {
        fetchOurValues(res);
      });
  };

  useEffect(() => {
    getValues();
  }, [language]);

  //fetch services
  const [ServicesTitle, fetchServicesTitle] = useState([]);
  const getServicesTitle = () => {
    fetch(`${hostLink}/API/api/services-title?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchServicesTitle(res);
      });
  };

  useEffect(() => {
    getServicesTitle();
  }, [language]);

  const [ServicesData, fetchServices] = useState([]);
  const getServices = () => {
    fetch(`${hostLink}/API/api/services?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchServices(res);
      });
  };

  useEffect(() => {
    getServices();
  }, [language]);

  //services single page
  const [ServicesSingle, fetchServicesSingle] = useState([]);
  const [AllServices, fetchAllServices] = useState([]);
  const [id, fetchid] = useState(1);

  const getAllServices = () => {
    fetch(`${hostLink}/API/api/services?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchAllServices(res);
        setIsLoading(false);
      });
  };
  const getServicesSingle = () => {
    fetch(`${hostLink}/API/api/service-single-page?id=` + id)
      .then((res) => res.json())
      .then((res) => {
        fetchServicesSingle(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getServicesSingle();
  }, [id]);

  useEffect(() => {
    getAllServices();
  }, []);

  //fetch logistics
  const [Logistics, fetchLogistics] = useState([]);
  const getLogistics = () => {
    fetch(`${hostLink}/API/api/logistics?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchLogistics(res);
      });
  };
  useEffect(() => {
    getLogistics();
  }, [language]);
  //fetch how does it work
  const [Work, fetchWork] = useState([]);
  const getWork = () => {
    fetch(`${hostLink}/API/api/work?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchWork(res);
      });
  };

  useEffect(() => {
    getWork();
  }, [language]);

  //fetching Alfaraby
  const [Faraby, fetchFaraby] = useState([]);
  const getFaraby = () => {
    fetch(`${hostLink}/API/api/alfaraby?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchFaraby(res);
      });
  };

  useEffect(() => {
    getFaraby();
  }, [language]);

  const [FarabyServices, fetchFarabyServices] = useState([]);
  const getFarabyServices = () => {
    fetch(`${hostLink}/API/api/alfaraby-services?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchFarabyServices(res);
      });
  };

  useEffect(() => {
    getFarabyServices();
  }, [language]);

  //fetch medical transport and services
  const [Medical, fetchMedical] = useState([]);
  const getMedical = () => {
    fetch(`${hostLink}/API/api/medical-transport?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchMedical(res);
      });
  };

  useEffect(() => {
    getMedical();
  }, [language]);

  //fetch healthCare
  const [Care, fetchCare] = useState([]);
  const getCare = () => {
    fetch(`${hostLink}/API/api/health-care?language=` + language)
      .then((res) => res.json())
      .then((res) => {
        fetchCare(res);
      });
  };

  useEffect(() => {
    getCare();
  }, [language]);

  return (
    <Router>
      <ScrollToTop />
      <Header
        Labels={Labels}
        language={language}
        setLanguage={setLanguage}
        Link={Link}
        hostLink={hostLink}
        direction={direction}
        setDirection={setDirection}
      />
      <Cookies
        Labels={Labels}
        direction={direction}
        language={language}
        hostLink={hostLink}
        Link={Link}
      />
      <div className="mt-28 lg:mt-20 xl:mt-24 2xl:mt-28">
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route
            path={Link}
            element={
              <HomepagePage
                Care={Care}
                Medical={Medical}
                FarabyServices={FarabyServices}
                Faraby={Faraby}
                Work={Work}
                Logistics={Logistics}
                ServicesTitle={ServicesTitle}
                ServicesData={ServicesData}
                OurValuesTitle={OurValuesTitle}
                OurValues={OurValues}
                WhoWeAre={WhoWeAre}
                Records={Records}
                Data1={Data1}
                Data2={Data2}
                Data3={Data3}
                Data4={Data4}
                Data5={Data5}
                Data6={Data6}
                Data7={Data7}
                Labels={Labels}
                direction={direction}
                language={language}
                hostLink={hostLink}
                Link={Link}
              />
            }
          />
          <Route
            path={`${Link}/service-singlepage/:id`}
            element={
              <Services_singlepage
                fetchid={fetchid}
                AllServices={AllServices}
                ServicesSingle={ServicesSingle}
                isLoading={isLoading}
                Labels={Labels}
                direction={direction}
                language={language}
                hostLink={hostLink}
                Link={Link}
              />
            }
          />
          <Route
            path={`${Link}/services`}
            element={
              <ServicesPage
                ServicesData={ServicesData}
                ServicesTitle={ServicesTitle}
                Labels={Labels}
                direction={direction}
                language={language}
                hostLink={hostLink}
                Link={Link}
              />
            }
          />
          <Route
            path={`${Link}/contact_us`}
            element={
              <ContactusPage
                ContactTitle={ContactTitle}
                DataContact={DataContact}
                isLoading={isLoading}
                Labels={Labels}
                direction={direction}
                language={language}
                hostLink={hostLink}
                Link={Link}
              />
            }
          />
          <Route
            path={`${Link}/branches`}
            element={
              <LOCATIONPage
                LocationTitle={LocationTitle}
                isLoading={isLoading}
                Labels={Labels}
                direction={direction}
                language={language}
                hostLink={hostLink}
                Link={Link}
              />
            }
          />
          <Route
            path={`${Link}/our_partners`}
            element={
              <OurPartners
                isLoading={isLoading}
                DataPartners={DataPartners}
                DataPartnersTitle={DataPartnersTitle}
                Labels={Labels}
                direction={direction}
                language={language}
                hostLink={hostLink}
                Link={Link}
              />
            }
          />
          <Route
            path={`${Link}/news`}
            element={
              <ACTUALITYPage
                News={News}
                isLoading={isLoading}
                Labels={Labels}
                language={language}
                direction={direction}
                hostLink={hostLink}
                Link={Link}
              />
            }
          />
          <Route
            path={`${Link}/news-singlepage/:id`}
            element={
              <News_singlepage
                fetchnewsid={fetchnewsid}
                News={News}
                SingleNews={SingleNews}
                isLoading={isLoading}
                Labels={Labels}
                direction={direction}
                language={language}
                hostLink={hostLink}
                Link={Link}
                NewsLink={`${Link}/news`}
              />
            }
          />
        </Routes>
      </div>
      <Footer
        Labels={Labels}
        direction={direction}
        language={language}
        hostLink={hostLink}
        Link={Link}
      />

      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/homepage" element={<Homepage />} />
      </Routes> */}
    </Router>
  );
};

export default ProjectRoutes;
