import { useTranslation } from "react-i18next";
import { Img } from "components/Img";
import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import { Button } from "components/Button";
import { useState } from "react";
import { useEffect } from "react";
import "./FrenchCare.css";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";

function FrenchCare(props) {
  const split_file_path = props.Care.images?.[0].file_path.split("/");

  if (split_file_path) {
    if (split_file_path.length > 0) {
      var blur_file_path = "";
      for (var i = 0; i < split_file_path.length - 1; i++) {
        blur_file_path = blur_file_path.concat(split_file_path[i], "/");
      }
      blur_file_path = blur_file_path.concat(
        "blures/",
        split_file_path[split_file_path.length - 1]
      );
    }
  }
  const split_file_path1 = props.Care.images?.[1].file_path.split("/");
  if (split_file_path1) {
    if (split_file_path1.length > 0) {
      var blur_file_path1 = "";
      for (var i = 0; i < split_file_path1.length - 1; i++) {
        blur_file_path1 = blur_file_path1.concat(split_file_path1[i], "/");
      }
      blur_file_path1 = blur_file_path1.concat(
        "blures/",
        split_file_path1[split_file_path1.length - 1]
      );
    }
  }
  return (
    <Stack className=" w-[100%] ]">
      <Column className=" items-center w-[100%]">
        <Column
          className="bg-cover mt-[10px] bg-indigo_600 bg-repeat items-center justify-end lg:mt-[75px] xl:mt-[36px] 2xl:mt-[87px] p-[15px] sm:p-[75px] lg:p-[75px] xl:p-[86px] 2xl:p-[97px] w-[100%]"
          style={{
            backgroundImage: "url('/images/img_frame57_600X1728.png')",
          }}
        >
          <Row className="items-center justify-center mt-[1px] w-[80%]">
            <LazyLoad offset={300}>
              <ProgressiveImage
                src={props.hostLink + props.Care.images?.[0].file_path}
                placeholder={props.hostLink + blur_file_path}
              >
                {(src) => (
                  <Img
                    src={src}
                    className=" rounded-radius10 w-[100%] lg:h-[180px] xl:h-[200px] 2xl:h-[250px] h-[221px] 3xl:h-[270px]  rounded-radius10 "
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>

            <LazyLoad offset={300}>
              <ProgressiveImage
                src={props.hostLink + props.Care.images?.[1].file_path}
                placeholder={props.hostLink + blur_file_path1}
              >
                {(src) => (
                  <Img
                    src={src}
                    className="lg:h-[180px] xl:h-[200px] 2xl:h-[250px] h-[221px] 3xl:h-[270px] lg:ml-[129px] xl:ml-[148px] 2xl:ml-[166px] ml-[40px] rounded-radius10"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
          </Row>
          <Text
            dangerouslySetInnerHTML={{ __html: props.Care.text }}
            className="frenchcare_text font-bold leading-[normal] lg:mt-[19px] xl:mt-[22px] 2xl:mt-[25px] mt-[30px] lg:text-[19px] xl:text-[22px] 2xl:text-[25px] text-[30px] text-center w-[56%]"
          ></Text>
        </Column>
      </Column>
    </Stack>
  );
}
export default FrenchCare;
