import React from "react";
import PropTypes from "prop-types";

const shapes = {
  RoundedBorder5: "rounded-radius5",
  icbCustomBorderTL5:
    "rounded-bl-[5px] rounded-br-[5px] rounded-tl-[5px] rounded-tr-[5px]",
  icbCircleBorder40: "rounded-radius40",
  icbCircleBorder22: "rounded-radius2221",
  icbCircleBorder20: "rounded-radius20",
};
const variants = {
  FillIndigo600: "bg-indigo_600 text-white_A700",
  FillWhiteA700d8: "bg-white_A700_d8 text-indigo_600",
  icbFillRed900e5: "bg-red_900_e5",
  icbFillIndigo600: "bg-indigo_600",
  icbFillWhiteA700: "bg-white_A700",
};
const sizes = {
  sm: "p-[10px] lg:p-[6px] xl:p-[7px] 2xl:p-[8px]",
  md: "lg:p-[11px] xl:p-[13px] 2xl:p-[15px] p-[18px]",
  smIcn: "xl:p-[5px] lg:p-[5px] 2xl:p-[6px] p-[8px]",
  mdIcn: "2xl:p-[10px] p-[13px] lg:p-[8px] xl:p-[9px]",
  lgIcn: "lg:p-[10px] xl:p-[11px] 2xl:p-[13px] p-[16px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} common-button `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf([
    "RoundedBorder5",
    "icbCustomBorderTL5",
    "icbCircleBorder40",
    "icbCircleBorder22",
    "icbCircleBorder20",
  ]),
  variant: PropTypes.oneOf([
    "FillIndigo600",
    "FillWhiteA700d8",
    "icbFillRed900e5",
    "icbFillIndigo600",
    "icbFillWhiteA700",
  ]),
  size: PropTypes.oneOf(["sm", "md", "smIcn", "mdIcn", "lgIcn"]),
};
Button.defaultProps = {
  className: "",
  leftIcon: null,
  rightIcon: null,
  shape: "icbCustomBorderTL5",
  variant: "icbFillRed900e5",
  size: "lgIcn",
};

export { Button };
