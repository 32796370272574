import { useTranslation } from "react-i18next";
import { Img } from "components/Img";
import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import { Button } from "components/Button";
import { useEffect, useState } from "react";
//new
import liveChatLogo from "../../assets/images/livechat logo 3.svg";
import wp from "../../assets/images/wp-icon.svg";
import ln from "../../assets/images/ln-icon.svg";
import insta from "../../assets/images/insta-icon.svg";
import facebook from "../../assets/images/facebook-icon.svg";

function Footer(props) {
  const { t } = useTranslation();
  const [Data, fetchData] = useState([]);
  const getData = () => {
    fetch(`${props.hostLink}/API/api/doctors?language=` + props.language)
      .then((res) => res.json())
      .then((res) => {
        fetchData(res);
      });
  };

  useEffect(() => {
    getData();
  }, [props.language]);

  const [DataInfo, fetchDataInfo] = useState([]);
  const getDataInfo = () => {
    fetch(`${props.hostLink}/API/api/info`)
      .then((res) => res.json())
      .then((res) => {
        fetchDataInfo(res);
      });
  };

  useEffect(() => {
    getDataInfo();
  }, []);

  return (
    <Stack className=" lg:mt-[142px]  xl:mt-[163px] mt-[142px] w-[100%]">
      <footer className="relative bg-gray_900 bottom-[0] w-[100%]">
        <Column
          className="bg-cover bg-repeat items-center justify-end 2xl:pt-[102px] pt-[79px] lg:pt-[79px]  xl:pt-[91px] w-[100%]"
          style={{ backgroundImage: "url('/images/img_group7.png')" }}
        >
          <Row className="w-[95%] lg:w-[88%]">
            <Column className="xl:mb-[30px] 2xl:mb-[30px] mb-[30px] lg:mb-[30px] xl:pr-[103px] 2xl:pr-[116px] pr-[90px] lg:pr-[90px] w-[29%]">
              <Img
                src="/images/img_group35.png"
                className=" lg:w-[35%] w-[75%]"
                alt="GroupThirtyFive"
              />
              <Text className="font-normal leading-[normal] lg:mt-[16px] xl:mt-[18px] 2xl:mt-[20px] mt-[16px] not-italic lg:text-[11px] xl:text-[13px] 2xl:text-[15px] text-[15px] text-white_A700 w-[100%]">
                {props.Labels.footer_under_logo}
              </Text>
            </Column>
            <Column className="mt-[1px] pb-[2px] pr-[2px] w-[41%]">
              <Text className="font-bold lg:mr-[200px] xl:mr-[320px] 2xl:mr-[361px] mr-[200px] lg:text-[15px] xl:text-[18px] 2xl:text-[20px] text-[20px] text-white_A700  w-[auto]">
                {props.Labels.our_doctors}
              </Text>
              <Column className="lg:ml-[19px] xl:ml-[22px] 2xl:ml-[25px] ml-[19px] xl:mr-[108px] 2xl:mr-[121px] mr-[94px] lg:mr-[94px] lg:mt-[12px] xl:mt-[14px] 2xl:mt-[16px] mt-[12px] w-[100%]">
                <Row className="items-center lg:mr-[135px] xl:mr-[154px] 2xl:mr-[174px] mr-[135px] w-[73%]">
                  <Img
                    src="/images/img_user_white_A700.png"
                    className="!object-contain lg:h-[17px] xl:h-[19px] 2xl:h-[21px] h-[23px] 3xl:h-[26px] my-[0] w-[8%]"
                    alt="user One"
                  />
                  <Text className="font-bold ml-[6px] lg:ml-[6px] xl:ml-[7px] 2xl:ml-[8px] lg:text-[14px] xl:text-[15px] 2xl:text-[18px] text-[18px] text-white_A700 uppercase w-[auto]">
                    {Data.name_1}
                  </Text>
                </Row>
                <Row className="lg:ml-[19px] xl:ml-[22px] 2xl:ml-[25px] ml-[19px] lg:mr-[39px] xl:mr-[45px] 2xl:mr-[50px] mr-[39px] mt-[6px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] w-[100%]">
                  <Img
                    src="/images/img_group41.png"
                    className="!object-contain lg:h-[11px] xl:h-[12px] 2xl:h-[14px] h-[20px] 3xl:h-[17px] my-[3px] w-[10%] lg:w-[5%] mt-0"
                    alt="GroupFortyOne"
                  />
                  <Text className="font-normal ml-[6px] lg:ml-[6px] xl:ml-[7px] 2xl:ml-[8px] not-italic lg:text-[12px] xl:text-[14px] 2xl:text-[15px] text-[15px] text-white_A700 w-[auto]">
                    {Data.description_1}
                  </Text>
                </Row>
                <Row className="lg:ml-[19px] xl:ml-[22px] 2xl:ml-[25px] ml-[19px] lg:mr-[39px] xl:mr-[45px] 2xl:mr-[50px] mr-[39px] mt-[6px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] w-[100%]">
                  <Img
                    src="/images/img_arrowdown_white_A700.png"
                    className="!object-contain lg:h-[11px] xl:h-[12px] 2xl:h-[14px] h-[17px] 3xl:h-[17px] my-[3px] w-[10%] lg:w-[5%] mt-0"
                    alt="arrowdown Two"
                  />
                  <a className="text-[#2c6d98]" href={`mailto:${Data.email_1}`}>
                    <Text className="font-normal lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] ml-[5px] not-italic lg:text-[12px] xl:text-[14px] 2xl:text-[15px] text-[15px] text-white_A700 w-[auto]">
                      {Data.email_1}
                    </Text>
                  </a>
                </Row>
                {/* <Row className="lg:ml-[18px] xl:ml-[21px] 2xl:ml-[18px] ml-[18px] lg:mr-[123px] xl:mr-[140px] 2xl:mr-[158px] mr-[123px] mt-[6px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] w-[50%]">
                  <Img
                    src="/images/img_mobile_white_A700.png"
                    className="!object-contain lg:h-[15px] xl:h-[17px] 2xl:h-[19px] h-[15px] 3xl:h-[23px] my-[0] w-[15%]"
                    alt="mobile One"
                  />
                  <Text className="font-normal not-italic lg:text-[12px] xl:text-[14px] 2xl:text-[15px] text-[12px] text-white_A700 w-[auto]">
                    FR:+33 6 86592482 -DXB:+971 50 650 43 87
                  </Text>
                </Row> */}

                <Row className="items-center lg:mr-[135px] xl:mr-[154px] 2xl:mr-[174px] mr-[135px] lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] mt-[5px] w-[100%]">
                  <Img
                    src="/images/img_user_white_A700.png"
                    className="lg:h-[17px] xl:h-[19px] 2xl:h-[21px] h-[23px] 3xl:h-[26px] w-[8%] !object-contain"
                    alt="user Two"
                  />
                  <Text className="font-bold ml-[6px] lg:ml-[6px] xl:ml-[7px] 2xl:ml-[8px] lg:text-[14px] xl:text-[15px] 2xl:text-[18px] text-[18px] text-white_A700 uppercase w-[auto]">
                    {Data.name_2}
                  </Text>
                </Row>

                <Row className="lg:ml-[19px] xl:ml-[22px] 2xl:ml-[25px] ml-[19px] lg:mr-[39px] xl:mr-[45px] 2xl:mr-[50px] mr-[39px] mt-[6px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] w-[100%]">
                  <Img
                    src="/images/img_group41.png"
                    className="!object-contain lg:h-[11px] xl:h-[12px] 2xl:h-[14px] h-[20px] 3xl:h-[17px] my-[3px] w-[10%] lg:w-[5%] mt-0"
                    alt="arrowdown Three"
                  />
                  <Text className="font-normal ml-[6px] lg:ml-[6px] xl:ml-[7px] 2xl:ml-[8px] not-italic lg:text-[12px] xl:text-[14px] 2xl:text-[15px] text-[15px] text-white_A700 w-[auto]">
                    {Data.description_2}
                  </Text>
                </Row>

                <Row className="items-center lg:ml-[18px] xl:ml-[21px] 2xl:ml-[24px] ml-[18px] lg:mr-[135px] xl:mr-[154px] 2xl:mr-[174px] mr-[135px] lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] mt-[5px] w-[100%]">
                  <Img
                    src="/images/img_arrowdown_white_A700.png"
                    className="!object-contain lg:h-[11px] xl:h-[12px] 2xl:h-[14px] h-[17px] 3xl:h-[17px] my-[3px] w-[10%] lg:w-[5%] mt-0"
                    alt="arrowdown Three"
                  />
                  <a className="text-[#2c6d98]" href={`mailto:${Data.email_2}`}>
                    <Text className="font-normal lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] ml-[5px] not-italic lg:text-[12px] xl:text-[14px] 2xl:text-[15px] text-[15px] text-white_A700 w-[auto]">
                      {Data.email_2}
                    </Text>
                  </a>
                </Row>
              </Column>
            </Column>
            <Column className="mb-[40px] lg:mb-[40px] xl:mb-[40px] 2xl:mb-[40px] mt-[1px] w-[29%]">
              <Text className="font-bold lg:text-[15px] xl:text-[18px] 2xl:text-[20px] text-[20px] text-white_A700  uppercase w-[auto]">
                {props.Labels.to_follow_case}
              </Text>
              <Column className="lg:ml-[19px] xl:ml-[22px] 2xl:ml-[25px] ml-[19px] lg:mr-[110px] xl:mr-[126px] 2xl:mr-[142px] mr-[110px] lg:mt-[12px] xl:mt-[14px] 2xl:mt-[15px] mt-[12px] w-[100%]">
                <Row className="items-center lg:mr-[38px] xl:mr-[43px] 2xl:mr-[49px] mr-[38px] w-[100%]">
                  <Img
                    src="/images/img_arrowdown_white_A700.png"
                    className="!object-contain lg:h-[11px] xl:h-[12px] 2xl:h-[14px] h-[17px] 3xl:h-[17px] my-[3px] w-[10%] lg:w-[5%] mt-0"
                    alt="arrowdown Four"
                  />
                  <Text className="font-normal lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] ml-[5px] not-italic lg:text-[12px] xl:text-[14px] 2xl:text-[15px] text-[15px] text-white_A700 w-[auto]">
                    <a className="text-white" href={`mailto:${DataInfo.email}`}>
                      {DataInfo.email}
                    </a>
                  </Text>
                </Row>

                <Row className="items-center  lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] mt-[5px] w-[100%]">
                  <Img
                    src="/images/img_mobile_white_A700.png"
                    className="!object-contain lg:h-[11px] xl:h-[12px] 2xl:h-[14px] h-[22px] 3xl:h-[17px] my-[3px] w-[10%] lg:w-[5%] mt-0"
                    alt="mobile Three"
                  />
                  <Text className="font-normal 2xl:ml-[5px] ml-[4px] lg:ml-[7px] xl:ml-[8px] not-italic lg:text-[12px] xl:text-[12px] 2xl:text-[14px] text-[15px] text-white_A700 w-[auto]">
                    {props.Labels.france} :{DataInfo.phone_1}
                  </Text>
                </Row>
                <Text className="font-normal lg:ml-[20px] xl:ml-[24px] 2xl:ml-[29px] ml-[30px] xl:mr-[3px] lg:mr-[3px] 2xl:mr-[4px] mr-[3px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] mt-[3px] not-italic lg:text-[12px] xl:text-[12px] 2xl:text-[14px] text-[15px] text-white_A700 w-[auto]">
                  {props.Labels.saudi_arabia} : {DataInfo.phone_3}
                </Text>
                <Row className="items-center lg:ml-[18px] xl:ml-[21px] 2xl:ml-[24px] ml-[18px] lg:mt-[18px] xl:mt-[21px] 2xl:mt-[24px] mt-[18px]">
                  <a
                    target="_blank"
                    className="!bg-transparent !w-8 !h-8"
                    href="https://www.facebook.com/medeva.org/"
                  >
                    <img
                      src={facebook}
                      alt="Whatsapp"
                      className="!w-full !h-full !object-fill"
                    />
                  </a>
                  <a
                    target="_blank"
                    className="!bg-transparent !ml-5 !w-8 !h-8"
                    href="https://www.instagram.com/medevasan/"
                  >
                    <img
                      src={insta}
                      alt="Whatsapp"
                      className="!w-full !h-full !object-fill"
                    />
                  </a>
                  <a
                    className=" !bg-transparent !ml-5 !w-10 !h-10"
                    target="_blank"
                    href="https://www.linkedin.com/company/medevasan/"
                  >
                    <img
                      src={ln}
                      alt="Whatsapp"
                      className="!w-full !h-full !object-fill"
                    />
                  </a>
                  <a
                    className="text-white !ml-5"
                    target="_blank"
                    href="https://wa.me/+33491321936"
                  >
                    <img src={wp} alt="Whatsapp" className="!w-7 !h-7" />
                  </a>
                </Row>
              </Column>
            </Column>
          </Row>
          <Stack className="lg:h-[47px] xl:h-[54px] 2xl:h-[61px] h-[47px] 3xl:h-[73px] lg:mt-[33px] xl:mt-[37px] 2xl:mt-[42px] mt-[33px] w-[100%]">
            <div className="absolute bg-gradient2  lg:h-[47px] xl:h-[54px] 2xl:h-[61px] h-[47px] 3xl:h-[73px] rounded-t-2xl w-[100%]"></div>
            <Text className="absolute font-normal inset-x-[0] mx-[auto] not-italic lg:text-[12px] xl:text-[14px] 2xl:text-[15px] text-[15px] text-white_A700 top-[19%] w-[max-content]">
              {props.Labels.rights}
            </Text>
          </Stack>
        </Column>
      </footer>
      <Row className="mt-[-50px]  absolute bg-indigo_600 inset-x-[0] items-end mx-[auto] xl:pt-[3px] lg:pt-[3px] 2xl:pt-[4px] pt-[3px] xl:px-[3px] lg:px-[3px] 2xl:px-[4px] px-[5px] rounded-radius10 top-[0] w-[76%]">
        <Img
          src="/images/img_send.png"
          className="!object-contain lg:h-[47px] xl:h-[53px] 2xl:h-[60px] h-[47px] 3xl:h-[72px] lg:mb-[14px] xl:mb-[19px] 2xl:mb-[24px] mb-[14px] w-[7%]"
          alt="send"
        />
        <Text className="w-[80%] font-bold lg:mb-[29px] xl:mb-[33px] 2xl:mb-[37px] mb-[29px] lg:ml-[23px] xl:ml-[26px] 2xl:ml-[30px] ml-[23px] lg:mt-[25px] xl:mt-[28px] 2xl:mt-[32px] mt-[25px] lg:text-[21px]  xl:text-[24px] 2xl:text-[27px] text-[18px] text-white_A700">
          {props.Labels.we_are_ready}
        </Text>
        <Img
          src="/images/img_group37.png"
          className="h-[70px] 3xl:h-[108px] lg:h-[70px] xl:h-[80px] 2xl:h-[90px] absolute right-[0%] lg:mt-[11px] xl:mt-[12px] 2xl:mt-[14px] mt-[11px] w-[9%]"
          alt="GroupThirtySeven"
        />
      </Row>
      <div className="!absolute !right-14 !bottom-10 lg:!bottom-14 !w-[100px] !h-[100px]">
        <a
          className="text-white !w-min !h-min"
          target="_blank"
          href="https://medeva.3cx.eu/medeva"
        >
          <img
            src={liveChatLogo}
            className="!w-full !h-full !object-contain"
            alt="Live Chat With Medeva"
          />
        </a>
      </div>
    </Stack>
  );
}
export default Footer;
