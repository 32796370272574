import React from "react";

import { Column } from "components";
import AnimatedCarousel from "components/animatedCarousel/animatedCarousel";
import MedicalTransportAndServices from "components/MedicalTransportAndServices/MedicalTransportAndServices";
import HowDoesItWork from "components/HowDoesItWork/HowDoesItWork";
import LogisticAndMedicalExpertise from "components/LogisticAndMedicalExpertise/LogisticAndMedicalExpertise";
import WhoWeAre from "components/WhoWeAre/WhoWeAre";
import SwiperSlideValues from "components/SwiperSlideValues/SwiperSlideValues";
import AlfarabyCollaboration from "components/AlfarabyCollaboration/AlfarabyCollaboration";
import FrenchCare from "components/FrenchCare/FrenchCare";
import { useState } from "react";
import { useEffect } from "react";
import ServicesPage from "components/services/services";

const HomepagePage = (props) => {
  console.log(props.FarabyServices);
  return (
    <>
      <Column
        style={{ direction: props.direction == 2 ? "rtl" : "ltr" }}
        className="bg-gray_100 font-productsans items-center mx-[auto] w-[100%]"
      >
        <AnimatedCarousel
          Data1={props.Data1}
          Data2={props.Data2}
          Data3={props.Data3}
          Data4={props.Data4}
          Data5={props.Data5}
          Data6={props.Data6}
          Data7={props.Data7}
          style={{ direction: "ltr" }}
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
        />

        <WhoWeAre
          WhoWeAre={props.WhoWeAre}
          Records={props.Records}
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
        />

        <SwiperSlideValues
          OurValuesTitle={props.OurValuesTitle}
          OurValues={props.OurValues}
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
        />
        <ServicesPage
          ServicesTitle={props.ServicesTitle}
          ServicesData={props.ServicesData}
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
        />
        <HowDoesItWork
          Work={props.Work}
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
        />
        <LogisticAndMedicalExpertise
          Logistics={props.Logistics}
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
        />
        <AlfarabyCollaboration
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
          FarabyServices={props.FarabyServices}
          Faraby={props.Faraby}
        />
        {/* <ValuesPage /> */}
        <MedicalTransportAndServices
          Medical={props.Medical}
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
        />

        {/* <ComingSoon /> */}

        <FrenchCare
          Care={props.Care}
          language={props.language}
          hostLink={props.hostLink}
          Link={props.Link}
        />
      </Column>
    </>
  );
};

export default HomepagePage;
