import { Img } from "components/Img";
import { Column } from "components/Column";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
function AlfarabyCollaboration(props) {
  const split_file_path0 = props.Faraby.images?.[0]?.file_path?.split("/");
  if (split_file_path0) {
    if (split_file_path0?.length > 0) {
      var blur_file_path0 = "";
      for (var i = 0; i < split_file_path0?.length - 1; i++) {
        blur_file_path0 = blur_file_path0?.concat(split_file_path0[i], "/");
      }
      blur_file_path0 = blur_file_path0?.concat(
        "blures/",
        split_file_path0[split_file_path0?.length - 1]
      );
    }
  }

  const split_file_path1 = props.Faraby.images?.[1]?.file_path?.split("/");
  if (split_file_path1) {
    if (split_file_path1?.length > 0) {
      var blur_file_path1 = "";
      for (var i = 0; i < split_file_path1?.length - 1; i++) {
        blur_file_path1 = blur_file_path1?.concat(split_file_path1[i], "/");
      }
      blur_file_path1 = blur_file_path1?.concat(
        "blures/",
        split_file_path1[split_file_path1?.length - 1]
      );
    }
  }

  const split_file_path2 =
    props.FarabyServices?.images?.[0]?.file_path?.split("/");
  if (split_file_path2) {
    if (split_file_path2?.length > 0) {
      var blur_file_path2 = "";
      for (var i = 0; i < split_file_path2?.length - 1; i++) {
        blur_file_path2 = blur_file_path2?.concat(split_file_path2[i], "/");
      }
      blur_file_path2 = blur_file_path2?.concat(
        "blures/",
        split_file_path2[split_file_path2?.length - 1]
      );
    }
  }

  const split_file_path3 =
    props.FarabyServices.images?.[1]?.file_path?.split("/");
  if (split_file_path3) {
    if (split_file_path3.length > 0) {
      var blur_file_path3 = "";
      for (var i = 0; i < split_file_path3?.length - 1; i++) {
        blur_file_path3 = blur_file_path3?.concat(split_file_path3[i], "/");
      }
      blur_file_path3 = blur_file_path3?.concat(
        "blures/",
        split_file_path3[split_file_path3?.length - 1]
      );
    }
  }
  return (
    <div className="lg:px-[126px] bg-white  xl:px-[126px] 2xl:px-[126px] 3xl:px-[328px] px-[32px]">
      <Stack className=" w-[100%]">
        {props.Faraby.images?.length > 0 && (
          <div className="float-left w-[45%] lg:w-[50%] lg:mt-[36px] xl:mt-[42px] 2xl:mt-[47px] mt-[57px]">
            <LazyLoad offset={300}>
              <ProgressiveImage
                src={props.hostLink + props.Faraby.images?.[0]?.file_path}
                placeholder={props.hostLink + blur_file_path0}
                className="!object-contain rounded-radius10 lg:h-[500px] lg:w-full h-[50%]"
              >
                {(src) => (
                  <Img
                    src={src}
                    className="!object-contain rounded-radius10 lg:h-[500px] lg:w-full h-[50%]"
                    alt="FrameSixtyOne"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
            <LazyLoad offset={300}>
              <ProgressiveImage
                src={props.hostLink + props.Faraby.images?.[1]?.file_path}
                className=" !object-contain   lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] mt-[7px]"
                placeholder={props.hostLink + blur_file_path1}
              >
                {(src) => (
                  <Img
                    src={src}
                    className=" !object-contain   lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] mt-[7px]"
                    alt="contrast"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
          </div>
        )}
        {props.Faraby.text && (
          <Row
            className={`${
              props.Faraby.images?.length > 0
                ? "w-[50%] lg:w-[45%]"
                : "w-[100%]"
            } float-right  h-[max-content] inset-[0] justify-center
         m-[auto]  mx-[10px]`}
          >
            <Column
              dangerouslySetInnerHTML={{ __html: props.Faraby.text }}
              className="!block items-center lg:mb-[158px] xl:mb-[180px] 2xl:mb-[203px] mb-[100px] lg:ml-[35px] xl:ml-[40px] 2xl:ml-[45px] ml-[54px] lg:mt-[36px] xl:mt-[42px] 2xl:mt-[47px] mt-[57px] "
            ></Column>
          </Row>
        )}
      </Stack>
      <Stack className=" flex bg-white  lg:pl-[14px] xl:pl-[16px] 2xl:pl-[18px] pl-[22px] w-[100%]">
        {props.FarabyServices?.images?.length > 0 && (
          <div className="float-left w-[50%] lg:w-[50%] lg:mt-[36px] xl:mt-[42px] 2xl:mt-[47px] mt-[57px]">
            <LazyLoad offset={300}>
              <ProgressiveImage
                src={
                  props.hostLink + props.FarabyServices.images?.[0]?.file_path
                }
                placeholder={props.hostLink + blur_file_path2}
                className="!object-contain w-full rounded-radius10  lg:mt-[4px] mt-[107px]"
              >
                {(src) => (
                  <Img
                    src={src}
                    className="!object-contain w-full rounded-radius10  lg:mt-[4px] mt-[107px]"
                    alt="FrameSixtyOne"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
            <LazyLoad offset={300}>
              <ProgressiveImage
                src={
                  props.hostLink + props.FarabyServices?.images?.[1]?.file_path
                }
                placeholder={props.hostLink + blur_file_path3}
                className=" !object-contain   lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] mt-[107px]"
              >
                {(src) => (
                  <Img
                    src={src}
                    className=" !object-contain   lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] mt-[107px]"
                    alt="contrast"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>{" "}
          </div>
        )}
        {props.FarabyServices.text && (
          <Row className="float-right mt-0 h-[max-content] inset-[0] justify-center m-[auto] w-[50%]">
            <Column
              dangerouslySetInnerHTML={{ __html: props.FarabyServices.text }}
              className="!block items-center lg:mb-[158px] xl:mb-[180px] 2xl:mb-[203px] mb-[100px] lg:ml-[35px] xl:ml-[40px] 2xl:ml-[45px] ml-[54px] lg:mt-[36px] xl:mt-[42px] 2xl:mt-[47px] mt-[57px]"
            ></Column>
          </Row>
        )}
      </Stack>
    </div>
  );
}
export default AlfarabyCollaboration;
