import React from "react";

import { Column, Stack, Row, Img, Text, List, Button } from "components";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ProgressiveImage from "react-progressive-image";

const ContactusPage = (props) => {
  const split_file_path = props.ContactTitle.images?.[0].file_path.split("/");
  if (split_file_path) {
    if (split_file_path.length > 0) {
      var blur_file_path = "";
      for (var i = 0; i < split_file_path.length - 1; i++) {
        blur_file_path = blur_file_path.concat(split_file_path[i], "/");
      }
      blur_file_path = blur_file_path.concat(
        "blures/",
        split_file_path[split_file_path.length - 1]
      );
    }
  }
  return (
    <>
      {props.isLoading ? (
        <div
          className="mt-[300px]  spinner-border text-primary  relative ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden ">Loading...</span>
        </div>
      ) : (
        <Column
          style={{ direction: props.direction == 2 ? "rtl" : "ltr" }}
          className="bg-white_A700 font-productsans items-center mx-[auto] pb-[1px] w-[100%]"
        >
          <Stack className="w-[100%]">
            <Column>
              <ProgressiveImage
                src={props.hostLink + props.ContactTitle.images?.[0].file_path}
                placeholder={props.hostLink + blur_file_path}
              >
                {(src) => (
                  <span
                    style={{
                      backgroundImage: `url(${src})`,
                    }}
                    className="!bg-no-repeat !bg-center !bg-cover h-[0px] justify-end xl:p-[175px] 2xl:p-[175px] 3xl:p-[200px] lg:p-[150px] p-[150px] top-[0] w-[100%]"
                  />
                )}
              </ProgressiveImage>
              <span className="absolute xl:p-[103px] 2xl:p-[115px] 3xl:p-[139px] lg:p-[90px] p-[90px]">
                <Text className=" relative top-16 font-bold lg:ml-[42px] xl:ml-[48px] 2xl:ml-[54px] 3xl:ml-[65px] xl:mt-[3px] lg:mt-[3px] mt-[3px] 2xl:mt-[4px] 3xl:mt-[5px] lg:text-[45px] text-[45px] xl:text-[51px] 2xl:text-[58px] 3xl:text-[70px] text-indigo_600 w-[auto]">
                  {props.ContactTitle.title}
                </Text>
                <Row className=" relative top-8 items-center lg:ml-[42px] ml-[42px] xl:ml-[48px] 2xl:ml-[54px] 3xl:ml-[65px] mr-[auto] lg:mt-[12px] :mt-[12px] xl:mt-[14px] 2xl:mt-[15px] 3xl:mt-[19px] w-[14%]">
                  <Img
                    src="/images/img_home.svg"
                    className="2xl:h-[11px] 3xl:h-[13px] lg:h-[8px] h-[8px] xl:h-[9px] 2xl:w-[10px] 3xl:w-[12px] lg:w-[7px] w-[7px] xl:w-[8px]"
                    alt="Home Five"
                  />
                  <Link
                    onClick={window["carousel"]}
                    to={{ pathname: `${props.Link}/` }}
                  >
                    <Text className="font-normal xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[14px] lg:ml-[9px] ml-[9px] not-italic lg:text-[10px] text-[10px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-gray_700 w-[auto]">
                      {props.Labels.home}
                    </Text>
                  </Link>
                  <i className="bi bi-arrow-right text-black xl:ml-[10px] 2xl:w-[10px] 3xl:w-[12px] lg:w-[7px] w-[7px] xl:w-[8px]"></i>
                  <Text className="whitespace-nowrap font-normal 2xl:ml-[10px] 3xl:ml-[13px] lg:ml-[8px] ml-[8px] xl:ml-[9px] not-italic lg:text-[10px] text-[10px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-indigo_600 w-[auto]">
                    {props.ContactTitle.title}
                  </Text>
                </Row>
              </span>
            </Column>
            <Column
              className=" bg-cover bg-repeat bottom-[13%] items-center 3xl:p-[111px] xl:p-[82px] 2xl:p-[92px] top-[14%] w-[100%]"
              style={{ backgroundImage: "url('/images/img_group2.png')" }}
            >
              <List
                className="gap-[0] min-h-[auto] w-[90%] lg:w-[79%]"
                orientation="vertical"
              >
                {/* <Row className="bg-gray_100 items-center justify-center  lg:mt-[25px] mt-[25px] xl:mt-[29px] 2xl:mt-[33px] 3xl:mt-[40px] lg:pl-[32px] pl-[32px] xl:pl-[37px] 2xl:pl-[41px] 3xl:pl-[50px] rounded-radius20 w-[100%]">
                <Stack className="bg-white_A700 lg:h-[163px] h-[163px] xl:h-[186px] 2xl:h-[209px] 3xl:h-[251px] lg:px-[32px] px-[32px] xl:px-[37px] 2xl:px-[41px] 3xl:px-[50px] rounded-radius10 shadow-lg lg:w-[162px] w-[162px] xl:w-[185px] 2xl:w-[208px] 3xl:w-[250px]">
                  <Img
                    src="/images/img_usersthreefil.svg"
                    className="!object-contain absolute 2xl:h-[111px] 3xl:h-[134px] lg:h-[87px] h-[87px] xl:h-[99px] inset-[0] justify-center m-[auto] w-[59%]"
                    alt="usersthreeFil"
                  />
                </Stack>
                <Stack className=" lg:h-[260px] h-[260px] xl:h-[297px] 2xl:h-[334px] 3xl:h-[401px] lg:ml-[32px] ml-[32px] xl:ml-[37px] 2xl:ml-[41px] 3xl:ml-[50px] w-[100%]">
                  <Column
                    className="opacity-50 absolute bg-cover bg-repeat lg:h-[260px] h-[260px] xl:h-[297px] 2xl:h-[334px] 3xl:h-[401px] items-end justify-end lg:px-[19px] px-[19px]  xl:px-[22px] 2xl:px-[25px] 3xl:px-[30px] right-[0] lg:w-[259px] w-[259px] xl:w-[296px] 2xl:w-[333px] 3xl:w-[400px]"
                    style={{ backgroundImage: "url('/images/medical.gif')" }}
                  >
                    <Button
                      className=" mb-2 flex items-center justify-center text-center  xl:mt-[220px]"
                      rightIcon={
                        <Img
                          src="/images/img_minimize.svg"
                          className="!object-contain text-center lg:w-[4px] w-[4px] lg:h-[5px] h-[5px] lg:ml-[6px] ml-[6px] xl:w-[5px] xl:h-[6px] xl:ml-[7px] 2xl:w-[5px] 2xl:h-[6px] 2xl:ml-[8px] 3xl:w-[7px] 3xl:h-[8px] 3xl:ml-[10px]"
                          alt="minimize"
                        />
                      }
                    >
                      <div className="text-white bg-transparent font-bold 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] text-[7px] xl:text-[8px]">
                        CONTACT US
                      </div>
                    </Button>
                  </Column>
                  <Text className="relative font-bold lg:leading-[35px] leading-[35px] xl:leading-[40px] 2xl:leading-[45px] 3xl:leading-[55px] left-[0] lg:text-[32px] text-[32px] xl:text-[37px] 2xl:text-[41px] 3xl:text-[50px] text-indigo_600 top-[21%] w-[100%]">
                  Case Management
                  </Text>
                  <Text className="relative w-[75%]  bottom-[-25%] font-normal left-[0] not-italic lg:text-[17px] text-[17px] xl:text-[18px] 2xl:text-[22px] 3xl:text-[27px] text-indigo_600 w-[auto]">
                  •From Europe: + 33 4 91321932<br/>
    •From The Middle East: +971043509209<br/>
    •From Saudi Arabia: + 966920033435

                  </Text>
                </Stack>
              </Row> */}
                {props.DataContact.map((contact, i) => {
                  return (
                    <Row
                      key={i}
                      className="bg-gray_100 items-center justify-end lg:my-[12px] my-[12px] xl:my-[14px] 2xl:my-[16px] 3xl:my-[20px] lg:pl-[32px] pl-[32px] xl:pl-[37px] 2xl:pl-[41px] 3xl:pl-[50px] rounded-radius20 w-[100%]"
                    >
                      <Stack className="bg-white_A700 lg:h-[163px] h-[163px] xl:h-[186px] 2xl:h-[209px] 3xl:h-[251px] lg:px-[28px] px-[28px] xl:px-[32px] 2xl:px-[36px] 3xl:px-[44px] rounded-radius10 shadow-lg lg:w-[162px] w-[162px] xl:w-[185px] 2xl:w-[208px] 3xl:w-[250px]">
                        <Img
                          src={`${props.hostLink}${contact.file_path}`}
                          className="absolute  h-[180px]  inset-[0] justify-center m-[auto] "
                          alt="Vector Two"
                        />
                      </Stack>
                      <Stack className=" lg:h-[260px] h-[260px] xl:h-[297px] 2xl:h-[334px] 3xl:h-[401px] lg:ml-[32px] ml-[32px] xl:ml-[37px] 2xl:ml-[41px] 3xl:ml-[50px] w-[74%]">
                        <Column
                          className="opacity-50 absolute bg-cover bg-repeat lg:h-[260px] h-[260px] xl:h-[297px] 2xl:h-[334px] 3xl:h-[401px] items-end justify-end lg:px-[19px] px-[19px] xl:px-[22px] 2xl:px-[25px] 3xl:px-[30px] right-[0] lg:w-[259px] w-[259px] xl:w-[296px] 2xl:w-[333px] 3xl:w-[400px]"
                          style={{
                            backgroundImage: "url('/images/medical.gif')",
                          }}
                        >
                          <a href={`mailto:${contact.link}`}>
                            <Button
                              className=" mb-2 flex items-center justify-center  text-center "
                              rightIcon={
                                <Img
                                  src="/images/img_minimize.svg"
                                  className="!object-contain text-center lg:w-[4px] w-[10px] lg:h-[5px] h-[10px] lg:ml-[6px] ml-[6px] xl:w-[5px] xl:h-[6px] xl:ml-[7px] 2xl:w-[5px] 2xl:h-[6px] 2xl:ml-[8px] 3xl:w-[7px] 3xl:h-[8px] 3xl:ml-[10px]"
                                  alt="minimize"
                                />
                              }
                            >
                              <div className="text-white bg-transparent font-bold 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] text-[10px] xl:text-[8px]">
                                CONTACT US
                              </div>
                            </Button>
                          </a>
                        </Column>
                        <div
                          className="relative top-[10%] lg:top-[20%] w-[80%]"
                          dangerouslySetInnerHTML={{ __html: contact.text }}
                        ></div>
                      </Stack>
                    </Row>
                  );
                })}
                {/* <Row className="bg-gray_100 items-center justify-end lg:my-[12px] my-[12px] xl:my-[14px] 2xl:my-[16px] 3xl:my-[20px] lg:pl-[32px] pl-[32px] xl:pl-[37px] 2xl:pl-[41px] 3xl:pl-[50px] rounded-radius20 w-[100%]">
                <Stack className="bg-white_A700 lg:h-[163px] h-[163px] xl:h-[186px] 2xl:h-[209px] 3xl:h-[251px] lg:px-[28px] px-[28px] xl:px-[32px] 2xl:px-[36px] 3xl:px-[44px] rounded-radius10 shadow-lg lg:w-[162px] w-[162px] xl:w-[185px] 2xl:w-[208px] 3xl:w-[250px]">
                  <Img
                    src="/images/s2.png"
                    className="!object-contain absolute lg:h-[104px] h-[104px]  xl:h-[119px] 2xl:h-[134px] 3xl:h-[161px] inset-[0] justify-center m-[auto] w-[80%]"
                    alt="Vector Two"
                  />
                </Stack>
                <Stack className=" lg:h-[260px] h-[260px] xl:h-[297px] 2xl:h-[334px] 3xl:h-[401px] lg:ml-[32px] ml-[32px] xl:ml-[37px] 2xl:ml-[41px] 3xl:ml-[50px] w-[74%]">
                  <Column
                    className="opacity-50 absolute bg-cover bg-repeat lg:h-[260px] h-[260px] xl:h-[297px] 2xl:h-[334px] 3xl:h-[401px] items-end justify-end lg:px-[19px] px-[19px] xl:px-[22px] 2xl:px-[25px] 3xl:px-[30px] right-[0] lg:w-[259px] w-[259px] xl:w-[296px] 2xl:w-[333px] 3xl:w-[400px]"
                    style={{
                      backgroundImage: "url('/images/medical.gif')",
                    }}
                  >
                    <a href="mailto:ibadaoui@medeva.org">
                      <Button
                        className=" mb-2 flex items-center justify-center  text-center "
                        rightIcon={
                          <Img
                            src="/images/img_minimize.svg"
                            className="!object-contain text-center lg:w-[4px] w-[10px] lg:h-[5px] h-[10px] lg:ml-[6px] ml-[6px] xl:w-[5px] xl:h-[6px] xl:ml-[7px] 2xl:w-[5px] 2xl:h-[6px] 2xl:ml-[8px] 3xl:w-[7px] 3xl:h-[8px] 3xl:ml-[10px]"
                            alt="minimize"
                          />
                        }
                      >
                        <div className="text-white bg-transparent font-bold 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] text-[10px] xl:text-[8px]">
                          CONTACT US
                        </div>
                      </Button>
                    </a>
                  </Column>
                  <Column className="absolute left-[0] top-[20%] w-[75%]">
                    <Text className="font-bold ml-[3px] lg:text-[32px] text-[32px] xl:text-[37px] 2xl:text-[41px] 3xl:text-[50px] text-indigo_600 w-[auto]">
                      Partnership
                    </Text>

                    <Row className="ml-[1px] lg:mt-[12px] mt-[12px] xl:mt-[14px] 2xl:mt-[15px] 3xl:mt-[19px] w-[86%]">
                      <Img
                        src="/images/img_trash.svg"
                        className="!object-contain lg:h-[11px] h-[11px] xl:h-[12px] 2xl:h-[14px] 3xl:h-[17px] w-[5%]  mt-[5px]"
                        alt="trash One"
                      />
                      <Text className="font-normal  mt-[1px] not-italic lg:text-[12px] text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[18px] text-indigo_600 w-[auto]">
                        Dr. Issam Badaoui | Senior Medical Director –
                        Partnership
                      </Text>
                    </Row>
                    <Row className="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] mt-[7px] xl:mt-[8px] w-[54%]">
                      <Img
                        src="/images/img_mail_14X19.svg"
                        className="!object-contain mt-[5px] lg:h-[10px] h-[10px] xl:h-[11px] 2xl:h-[12px] 3xl:h-[15px]  w-[9%]"
                        alt="mail Two"
                      />
                      <Text className="font-normal not-italic lg:text-[12px] text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[18px] text-indigo_600 w-[auto]">
                        <a
                          className="text-[#2c6d98]"
                          href="mailto:ibadaoui@medeva.org"
                        >
                          ibadaoui@medeva.org
                        </a>
                      </Text>
                    </Row>
                  </Column>
                  <Text className="w-[75%] absolute bottom-[5%] font-normal left-[0] not-italic lg:text-[17px] text-[17px] xl:text-[18px] 2xl:text-[22px] 3xl:text-[27px] text-indigo_600 w-[auto]">
                    If you need to use our services, if you want to join our
                    network contact me.
                  </Text>
                </Stack>
              </Row>
              <Row className="bg-gray_100 items-center justify-end lg:my-[12px] my-[12px] xl:my-[14px] 2xl:my-[16px] 3xl:my-[20px] lg:pl-[32px] pl-[32px] xl:pl-[37px] 2xl:pl-[41px] 3xl:pl-[50px] rounded-radius20 w-[100%]">
                <Stack className="bg-white_A700 lg:h-[163px] h-[163px] xl:h-[186px] 2xl:h-[209px] 3xl:h-[251px] lg:px-[28px] px-[28px] xl:px-[32px] 2xl:px-[36px] 3xl:px-[44px] rounded-radius10 shadow-lg lg:w-[162px] w-[162px] xl:w-[185px] 2xl:w-[208px] 3xl:w-[250px]">
                  <Img
                    src="/images/img_vector.svg"
                    className="absolute lg:h-[104px] h-[104px] xl:h-[119px] 2xl:h-[134px] 3xl:h-[161px] inset-[0] justify-center m-[auto] w-[50%]"
                    alt="Vector One"
                  />
                </Stack>
                <Stack className="lg:h-[260px] h-[260px] xl:h-[297px] 2xl:h-[334px] 3xl:h-[401px] lg:ml-[32px] ml-[32px] xl:ml-[37px] 2xl:ml-[41px] 3xl:ml-[50px] w-[74%]">
                  <Column
                    className="opacity-50 absolute bg-cover bg-repeat lg:h-[260px] h-[260px] xl:h-[297px] 2xl:h-[334px] 3xl:h-[401px] items-end justify-end lg:px-[19px] px-[19px] xl:px-[22px] 2xl:px-[25px] 3xl:px-[30px] right-[0] lg:w-[259px] w-[259px] xl:w-[296px] 2xl:w-[333px] 3xl:w-[400px]"
                    style={{
                      backgroundImage: "url('/images/medical.gif')",
                    }}
                  >
                    <a href="mailto:pcardi@medeva.org">
                      <Button
                        className=" mb-2 flex items-center justify-center  text-center "
                        rightIcon={
                          <Img
                            src="/images/img_minimize.svg"
                            className="!object-contain text-center lg:w-[4px] w-[10px] lg:h-[5px] h-[10px] lg:ml-[6px] ml-[6px] xl:w-[5px] xl:h-[6px] xl:ml-[7px] 2xl:w-[5px] 2xl:h-[6px] 2xl:ml-[8px] 3xl:w-[7px] 3xl:h-[8px] 3xl:ml-[10px]"
                            alt="minimize"
                          />
                        }
                      >
                        <div className="text-white bg-transparent font-bold 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] text-[10px] xl:text-[8px]">
                          CONTACT US
                        </div>
                      </Button>
                    </a>
                  </Column>
                  <Column className="absolute left-[0] top-[22%] w-[75%]">
                    <Text className="font-bold ml-[3px] lg:text-[32px] text-[32px] xl:text-[37px] 2xl:text-[41px] 3xl:text-[50px] text-indigo_600 w-[auto]">
                      Medical Team
                    </Text>
                    <Row className="2xl:mt-[10px] 3xl:mt-[13px] lg:mt-[8px] mt-[8px] xl:mt-[9px] w-[100%]">
                      <Img
                        src="/images/img_trash.svg"
                        className="!object-contain lg:h-[11px] xl:h-[12px] 2xl:h-[14px] 3xl:h-[17px] w-[4%] mt-[5px]"
                        alt="trash"
                      />
                      <Text className="font-normal  mt-[1px] not-italic lg:text-[12px] text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[18px] text-indigo_600 w-[auto]">
                        Dr. Philippe Cardi | Senior Medical Director - Case
                        management
                      </Text>
                    </Row>
                    <Row className="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] mt-[7px] xl:mt-[8px] w-[49%]">
                      <Img
                        src="/images/img_mail_14X19.svg"
                        className="!object-contain mt-[5px] lg:h-[10px] h-[10px] xl:h-[11px] 2xl:h-[12px] 3xl:h-[15px]  w-[9%]"
                        alt="mail One"
                      />
                      <Text className="font-normal not-italic lg:text-[12px] text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[18px] text-indigo_600 w-[auto]">
                        <a
                          className="text-[#2c6d98]"
                          href="mailto:pcardi@medeva.org"
                        >
                          pcardi@medeva.org
                        </a>
                      </Text>
                    </Row>
                    {/* <Row className="items-center ml-[3px] xl:mt-[5px] lg:mt-[5px] mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[38%]">
                        <Img
                          src="/images/img_mobile.svg"
                          className="!object-contain lg:h-[15px] h-[15px] xl:h-[17px] 2xl:h-[19px] 3xl:h-[23px] w-[8%]"
                          alt="mobile"
                        />
                        <Text className="font-normal 2xl:ml-[10px] 3xl:ml-[12px] lg:ml-[7px] ml-[7px] xl:ml-[8px] not-italic lg:text-[12px] text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[18px] text-indigo_600 w-[auto]">
                          +&nbsp;33&nbsp;6&nbsp;11162222
                        </Text>
                      </Row> 
                  </Column>
                  <Text className="w-[75%] font-thin absolute bottom-[5%]  left-[0]  lg:text-[17px] text-[17px] xl:text-[18px] 2xl:text-[22px] 3xl:text-[27px] text-indigo_600 w-[auto]">
                    If you are a doctor, a nurse or a paramedic and you want to
                    join our team contact me.
                  </Text>
                </Stack>
              </Row> */}
              </List>
            </Column>
          </Stack>
        </Column>
      )}
    </>
  );
};

export default ContactusPage;
