import React from "react";

import { Stack, Column, Text, Img, Row } from "components";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";

const LOCATIONnewPage = (props) => {
  const split_file_path = props.LocationTitle.images?.[0].file_path.split("/");
  if (split_file_path) {
    if (split_file_path.length > 0) {
      var blur_file_path = "";
      for (var i = 0; i < split_file_path.length - 1; i++) {
        blur_file_path = blur_file_path.concat(split_file_path[i], "/");
      }
      blur_file_path = blur_file_path.concat(
        "blures/",
        split_file_path[split_file_path.length - 1]
      );
    }
  }
  return (
    <>
      {props.isLoading ? (
        <div
          className="mt-[300px]  spinner-border text-primary  relative ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden ">Loading...</span>
        </div>
      ) : (
        <Stack
          style={{ direction: props.direction == 2 ? "rtl" : "ltr" }}
          className="bg-white_A700 font-productsans mx-[auto] w-[100%]"
        >
          <Column>
            <ProgressiveImage
              src={props.hostLink + props.LocationTitle.images?.[0].file_path}
              placeholder={props.hostLink + blur_file_path}
            >
              {(src) => (
                <span
                  style={{
                    backgroundImage: `url(${src})`,
                  }}
                  className="!bg-no-repeat !bg-center !bg-cover justify-end xl:p-[175px] 2xl:p-[175px] 3xl:p-[200px] lg:p-[150px] p-[150px] top-[0] w-[100%]"
                />
              )}
            </ProgressiveImage>
            <span className="absolute xl:p-[103px] 2xl:p-[115px] 3xl:p-[139px] lg:p-[90px] p-[90px]">
              <Text className="font-bold lg:ml-[45px] ml-[45px] xl:ml-[51px] 2xl:ml-[58px] 3xl:ml-[70px] 3xl:mr-[1108px] lg:mr-[718px] mr-[718px] xl:mr-[821px] 2xl:mr-[923px] xl:mt-[3px] lg:mt-[3px] mt-[3px] 2xl:mt-[4px] 3xl:mt-[5px] lg:text-[45px] text-[45px] xl:text-[51px] 2xl:text-[58px] 3xl:text-[70px] text-indigo_600 w-[auto]">
                {props.LocationTitle.title}
              </Text>
              <Row className="items-center lg:ml-[42px] ml-[42px] xl:ml-[48px] 2xl:ml-[54px] 3xl:ml-[65px] mr-[auto] lg:mt-[12px] mt-[12px] xl:mt-[14px] 2xl:mt-[15px] 3xl:mt-[19px] w-[13%]">
                <Img
                  src="/images/img_home.svg"
                  className=" 2xl:w-[10px] 3xl:w-[12px] lg:w-[7px] w-[7px] xl:w-[8px]"
                  alt="Home"
                />
                <Link
                  onClick={window["carousel"]}
                  to={{ pathname: `${props.Link}/` }}
                >
                  <Text className="font-normal xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[14px] lg:ml-[9px] ml-[9px] not-italic lg:text-[10px] text-[10px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-gray_700 w-[auto]">
                    {props.Labels.home}
                  </Text>
                </Link>
                <i className="bi bi-arrow-right text-black xl:ml-[10px] 2xl:w-[10px] 3xl:w-[12px] lg:w-[7px] w-[7px] xl:w-[8px]"></i>
                <Text className="font-normal 2xl:ml-[10px] 3xl:ml-[13px] lg:ml-[8px] ml-[8px] xl:ml-[9px] not-italic lg:text-[10px] text-[10px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-indigo_600 w-[auto]">
                  {props.LocationTitle.title}
                </Text>
              </Row>
            </span>
          </Column>
          <Column
            className=" bg-cover bg-repeat bottom-[11%] lg:p-[46px] p-[46px] xl:p-[52px] 2xl:p-[59px] 3xl:p-[71px] top-[9%] w-[100%]"
            style={{ backgroundImage: "url('/images/img_group1.png')" }}
          >
            <Text className="font-bold ml-[3px] lg:mt-[29px] mt-[29px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[45px] lg:text-[27px] text-[27px] xl:text-[31px] 2xl:text-[35px] 3xl:text-[42px] text-red_900 underline uppercase w-[auto]">
              {props.Labels.europe}
            </Text>
            <Stack className="bg-gray_100  xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[15px] lg:mt-[9px] mt-[9px] rounded-radius20 shadow-bs w-[100%]">
              <Stack>
                <div>
                  <Stack className=" w-[100%]">
                    <Img
                      src="/images/img_dotsworldmap.png"
                      className="absolute h-full w-[100%]"
                      alt="dotsworldmap"
                    />
                    <Column className=" inset-[0] items-center justify-center m-[auto] w-[86%]">
                      <Row>
                        <Text className="font-bold mt-5 lg:text-[27px] text-[27px] xl:text-[31px] 2xl:text-[35px] 3xl:text-[42px] text-red_900 underline uppercase w-[auto]">
                          {props.Labels.france}
                        </Text>
                        <img
                          className="h-[25px] w-[50px] mt-14 lg:mt-16 ml-5"
                          src="/images/Flag_of_France.gif"
                        />
                      </Row>
                      <div className="container mt-14">
                        <div className="row">
                          {/* <StyledTitle aboutUs={t("Our Branches")} /> */}
                          <div
                            className="col-lg-4  col-md-6 col-sm-12 relative "
                            style={{ textAlign: "-webkit-center" }}
                          >
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d220.18827539307094!2d5.394977404848794!3d43.25154460791222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9b89d46098205%3A0xef4d7a4c0984e539!2s637%20Av.%20de%20Mazargues%2C%2013008%20Marseille%2C%20France!5e0!3m2!1sen!2slb!4v1663419171785!5m2!1sen!2slb"
                                className="rounded-2xl w-full"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <span className="p-10">
                              <h2
                                className=" text-center"
                                style={{ color: "#2c6d98" }}
                              >
                                Marseille
                              </h2>
                              <p className="text-center">
                                {props.Labels.head_office}
                              </p>
                            </span>
                          </div>
                          <div
                            className="col-lg-4  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5899.488983534919!2d4.877704067067743!3d45.74737498938869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4c1c34cf33117%3A0xb65e7945d91b3852!2s13%20Rue%20Professeur%20Florence%2C%2069003%20Lyon%2C%20France!5e0!3m2!1sen!2slb!4v1660726798720!5m2!1sen!2slb"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <h2
                              className="p-10 text-center"
                              style={{ color: "#2c6d98" }}
                            >
                              Lyon
                            </h2>
                          </div>
                          <div
                            className="col-lg-4  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2931.077747602871!2d2.8843751157011255!3d42.7232451200802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b06eef727e8291%3A0xcd39b409a138b20e!2s35%20Av.%20du%20Languedoc%2C%2066000%20Perpignan%2C%20France!5e0!3m2!1sen!2slb!4v1660727338468!5m2!1sen!2slb"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <h2
                              className="p-10 text-center"
                              style={{ color: "#2c6d98" }}
                            >
                              Perpignan
                            </h2>
                          </div>
                          <div
                            className="col-lg-4  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            {" "}
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.158774879625!2d3.8220222157227965!3d43.64486486098737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b6ac026bdb7215%3A0xc13a93a3b5e871c4!2s510%20Rue%20Louis%20Pasteur%2C%2034790%20Grabels%2C%20France!5e0!3m2!1sen!2slb!4v1660727470371!5m2!1sen!2slb"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <h2
                              className="p-10 text-center"
                              style={{ color: "#2c6d98" }}
                            >
                              Montpellier
                            </h2>
                          </div>
                          <div
                            className="col-lg-4  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            {" "}
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.794998982146!2d7.310190615724922!3d43.735585355116925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cdc4e3a2deae17%3A0xed8d7c0da88eaae5!2s36%20Bd%20Jean-Dominique%20Blanqui%2C%2006340%20La%20Trinit%C3%A9%2C%20France!5e0!3m2!1sen!2slb!4v1660728062134!5m2!1sen!2slb"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <h2
                              className="p-10 text-center"
                              style={{ color: "#2c6d98" }}
                            >
                              Nice
                            </h2>
                          </div>
                          <div
                            className="col-lg-4  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center", zIndex: 10 }}
                          >
                            {" "}
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl w-full"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5364.109126593826!2d7.3046574!3d47.760996!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47919b51a723575d%3A0x551b7ba13d03e31c!2sSOS%20BOOS%20Ambulances!5e0!3m2!1sen!2slb!4v1673348812413!5m2!1sen!2slb"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <h2
                              className="p-10 text-center"
                              style={{ color: "#2c6d98" }}
                            >
                              Mulhouse
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Column>
                    <Img
                      src="/images/earth illustration (1).gif"
                      className="absolute opacity-[0.1] bottom-[0] right-[0] w-[46%]"
                      alt="d7ae0170d3d5ffc"
                    />
                  </Stack>
                </div>
                {/* <Column
                className=" bg-cover bg-repeat bottom-[0] items-end lg:p-[12px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[20px] right-[0] w-[46%]"
                // style={{ backgroundImage: "url('/images/img_group3.png')" }}
              >
                <Text className="font-bold lg:mb-[340px] xl:mb-[389px] 2xl:mb-[437px] 3xl:mb-[525px] lg:mr-[116px] xl:mr-[133px] 2xl:mr-[150px] 3xl:mr-[180px] lg:text-[27px] xl:text-[31px] 2xl:text-[35px] 3xl:text-[42px] text-indigo_600 w-[auto]">
                  Marseille
                </Text>
              </Column> */}
              </Stack>
              {/* <Column className=" bottom-[4%] inset-x-[0] items-center mx-[auto] w-[22%]">
              <div className="bg-gray_800 rounded-radius20 lg:w-[226px] xl:w-[259px] 2xl:w-[291px] 3xl:w-[350px]"></div>
              <Text className="font-bold lg:mt-[25px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[39px] lg:text-[27px] xl:text-[31px] 2xl:text-[35px] 3xl:text-[42px] text-indigo_600 w-[auto]">
                Perpignan
              </Text>
            </Column> */}
            </Stack>
            <Text className="font-bold ml-[3px] 3xl:mt-[103px] lg:mt-[66px] mt-[66px] xl:mt-[76px] 2xl:mt-[85px] lg:text-[27px] text-[27px] xl:text-[31px] 2xl:text-[35px] 3xl:text-[42px] text-red_900 underline uppercase w-[auto]">
              {props.Labels.middle_east}
            </Text>
            <Stack className="bg-gray_100 xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[15px] lg:mt-[9px] mt-[9px] rounded-radius20 shadow-bs w-[100%]">
              <Stack className=" w-[100%]">
                <div className="  top-[0] w-[100%]">
                  <Stack className=" w-[100%]">
                    <Img
                      src="/images/img_dotsworldmap_1082X1728.png"
                      className="absolute w-[100%]"
                      alt="dotsworldmap One"
                    />
                    <Column className=" inset-x-[0] items-center mx-[auto] top-[4%]">
                      <Row>
                        <Text className="font-bold lg:text-[27px] text-[27px]  xl:text-[31px] 2xl:text-[35px] 3xl:text-[42px] text-red_900 underline uppercase w-[auto]">
                          {props.Labels.lebanon}
                        </Text>
                        <img
                          className="h-[25px] w-[50px] lg:!mt-[1rem] mt-[0.5rem] ml-5"
                          src="/images/Flag_of_Lebanon.gif"
                        />
                      </Row>
                      <div className="container mt-14">
                        <div className="row">
                          <div
                            className="col-lg-6  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            {" "}
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl "
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6627.727981436856!2d35.53033210013567!3d33.841618002453956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe33f933581f12722!2zMzPCsDUwJzM0LjMiTiAzNcKwMzEnNDIuOSJF!5e0!3m2!1sen!2slb!4v1660728417612!5m2!1sen!2slb"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <h2
                              className="p-10 text-center"
                              style={{ color: "#2c6d98" }}
                            >
                              Beirut
                            </h2>
                          </div>

                          <div
                            className="col-lg-6  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            {" "}
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3336.6902758073097!2d35.2357778!3d33.2484167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x22584b3c3d096949!2zMzPCsDE0JzU0LjMiTiAzNcKwMTQnMDguOCJF!5e0!3m2!1sen!2slb!4v1660728543865!5m2!1sen!2slb"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <h2
                              className="p-10 text-center"
                              style={{ color: "#2c6d98" }}
                            >
                              Tyre
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="container mt-14">
                        <div className="row">
                          <div
                            className="col-lg-6  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            <Row className="justify-center">
                              <Text className="font-bold  lg:text-[27px] text-[27px] xl:text-[31px] 2xl:text-[35px] 3xl:text-[42px] text-red_900 underline uppercase w-[auto]">
                                {props.Labels.uae}
                              </Text>
                              <img
                                className="h-[25px] w-[50px] lg:!mt-[1rem] mt-[0.5rem] ml-5"
                                src="/images/Flag_of_the_United_Arab_Emirates.gif"
                              />
                            </Row>
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2slb!4v1660728642454!5m2!1sen!2slb"
                                height=" 300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <span className="p-10 ">
                              <h2
                                className="text-center"
                                style={{ color: "#2c6d98" }}
                              >
                                Dubai
                              </h2>
                              <p className="text-center">
                                {props.Labels.head_office}
                              </p>
                            </span>
                          </div>
                          <div
                            className="col-lg-6  col-md-6 col-sm-12 relative"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            {" "}
                            <Row className="justify-center">
                              <Text className="font-bold  lg:text-[27px] text-[27px] xl:text-[31px] 2xl:text-[35px] 3xl:text-[42px] text-red_900 underline uppercase w-[auto]">
                                {props.Labels.saudi_arabia}
                              </Text>
                              <img
                                className="h-[25px] w-[50px] lg:!mt-[1rem] mt-[0.5rem] ml-5"
                                src="/images/Flag_of_Saudi_Arabia.gif"
                              />
                            </Row>
                            <div className="rounded-2xl border-2 border-[#2c6d98] shadow-md shadow-blue-500/40 w-max">
                              <iframe
                                className="rounded-2xl w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7455039.655145476!2d40.58064788685596!3d24.166258303209055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e7b33fe7952a41%3A0x5960504bc21ab69b!2sSaudi%20Arabia!5e0!3m2!1sen!2slb!4v1661334129442!5m2!1sen!2slb"
                                height="300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                            <span className="p-10 ">
                              <h2
                                className="text-center"
                                style={{ color: "#2c6d98" }}
                              >
                                {props.Labels.saudi_arabia}
                              </h2>
                              <p className="text-center">
                                {props.Labels.head_office}
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Column>
                  </Stack>
                </div>
                <Img
                  src="/images/earth illustration (1).gif"
                  className="absolute opacity-[0.1] bottom-[27%] right-[27%] w-[46%]"
                  alt="d7ae0170d3d5ffc"
                />
              </Stack>
              <Column className=" bottom-[4%] inset-x-[0] items-center mx-[auto] w-[22%]">
                <div className="bg-gray_800  rounded-radius20 lg:w-[226px] w-[226px] xl:w-[259px] 2xl:w-[291px] 3xl:w-[350px]"></div>
              </Column>
            </Stack>
          </Column>
        </Stack>
      )}
    </>
  );
};

export default LOCATIONnewPage;
