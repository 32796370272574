// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./SwiperSlideValues.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Scrollbar, A11y } from "swiper";
import { Img } from "components/Img";
import { Text } from "components/Text";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
// import Swiper core and required modules

function SwiperSlideValues(props) {
  SwiperCore.use([Pagination]);

  return (
    <div className={`w-full h-full`}>
      <Img
        src="images//img_group15.png"
        className="absolute w-[100%]"
        alt="GroupFifteen"
      />
      <div className=" col-span-12 text-center  ">
        <Text className="font-bold lg:text-[38px] text-[38px] xl:text-[44px] 2xl:text-[50px] 3xl:text-[60px] text-red_900 w-[auto]">
          {props.OurValuesTitle.title}
        </Text>
        <Text className="font-bold mb-4 lg:mt-[13px] mt-[13px] xl:mt-[15px] 2xl:mt-[17px] 3xl:mt-[21px] lg:text-[32px] text-[32px] xl:text-[37px] 2xl:text-[41px] 3xl:text-[50px] text-indigo_600 w-[auto]">
          {props.OurValuesTitle.brief_description}
        </Text>
      </div>

      <div className="h-full sm:hidden block text-center ">
        <Swiper
          className="h-full !py-10"
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={0}
          navigation
          pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          breakpoints={{
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1250: {
              slidesPerView: 4,
            },
          }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
        >
          {/* {NewProjects.map((project, i) => {
                return ( */}
          <div className="">
            {props.OurValues.map((Ourvalues, i) => {
              const split_file_path = Ourvalues.file_path.split("/");
              if (split_file_path.length > 0) {
                var blur_file_path = "";
                for (var i = 0; i < split_file_path.length - 1; i++) {
                  blur_file_path = blur_file_path.concat(
                    split_file_path[i],
                    "/"
                  );
                }
                blur_file_path = blur_file_path.concat(
                  "blures/",
                  split_file_path[split_file_path.length - 1]
                );
              }

              return (
                <SwiperSlide key={i}>
                  <a className="card">
                    <div className="card-hero">
                      <LazyLoad offset={300}>
                        <ProgressiveImage
                          src={`${props.hostLink}/${Ourvalues.file_path}`}
                          placeholder={`${props.hostLink}/${blur_file_path}`}
                          className="h-[175px]"
                        >
                          {(src) => <img src={src} className="h-[175px]" />}
                        </ProgressiveImage>
                      </LazyLoad>
                    </div>
                    <div className="card-header">
                      <h3>{Ourvalues.name}</h3>
                    </div>
                    <div className="card-body">
                      <p className="text-left">{Ourvalues.brief_description}</p>
                    </div>
                  </a>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
      </div>
    </div>
  );
}
export default SwiperSlideValues;
