import React from "react";
import { Link } from "react-router-dom";
import "./news.css";
const NewsCard = ({ image, title, description, news_id }) => {
  return (
    <Link
      to={{
        pathname: "/news-singlepage/" + news_id,
      }}
      className="card-news col-lg-3 col-md-4 col-sm-6 m-4"
    >
      <div className="card">
        <img className="card-image" src={image} alt={title} />
        <div className="panel">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>

          <a style={{}} className="read-more" aria-disabled="true">
            Read More + <i className="fa fa-long-arrow-right"></i>
          </a>
        </div>
      </div>
      {/* <div className="new-card-grid">
        <div className="image-box">
          <img src={image} alt={title} className="card-img-top img-fluid" />
        </div>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
        </div>
      </div> */}
    </Link>
  );
};

export default NewsCard;
